exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challenge-set-cities-and-municipalities-js": () => import("./../../../src/pages/challenge-set/cities-and-municipalities.js" /* webpackChunkName: "component---src-pages-challenge-set-cities-and-municipalities-js" */),
  "component---src-pages-challenge-set-corporate-social-responsibility-js": () => import("./../../../src/pages/challenge-set/corporate-social-responsibility.js" /* webpackChunkName: "component---src-pages-challenge-set-corporate-social-responsibility-js" */),
  "component---src-pages-challenge-set-global-cause-js": () => import("./../../../src/pages/challenge-set/global-cause.js" /* webpackChunkName: "component---src-pages-challenge-set-global-cause-js" */),
  "component---src-pages-challenge-set-net-zero-goals-js": () => import("./../../../src/pages/challenge-set/net-zero-goals.js" /* webpackChunkName: "component---src-pages-challenge-set-net-zero-goals-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-organizers-js": () => import("./../../../src/pages/organizers.js" /* webpackChunkName: "component---src-pages-organizers-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

